<!--
 * @Author: your name
 * @Date: 2020-04-13 21:27:49
 * @LastEditTime: 2020-04-24 11:14:00
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \b2b2c-admin\src\page\system\jichu.vue
 -->
<template>
    <div>
        <div style="padding-bottom: 10px;">
            <!-- <el-button type="primary" style="margin-left: 20px;" >添加</el-button>

            <el-button type="primary" >删除</el-button>-->

            <el-button type="success" disabled>显示</el-button>

            <el-button type="info" disabled>隐藏</el-button>
        </div>
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column fixed prop="date" label="日期" width="150"></el-table-column>
            <el-table-column prop="name" label="姓名" width="120"></el-table-column>
            <el-table-column prop="province" label="省份" width="120"></el-table-column>
            <el-table-column prop="city" label="市区" width="120"></el-table-column>
            <el-table-column prop="address" label="地址" width="300"></el-table-column>
            <el-table-column prop="zip" label="邮编" width="120"></el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scoped">
                    <el-button type="text" size="small" @click="check">查看</el-button>
                    <el-button type="text" size="small" @click="bianji">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { request } from '../../common/request';
export default {
    //     methods: {
    //       handleClick(row) {
    //         console.log(row);
    //       },
    //       fan(){
    //              request.post("/shop/ad/list", this.queryParams).then((ret) => {
    //                     this.loading = false;
    //                     if (ret.code == 200) {
    //                         this.list = ret.data;
    //                         this.pageInfo.data.map(function(val){
    //                             val.ctime = deal.timestamp(val.ctime,'unix');
    //                             val.etime = deal.timestamp(val.etime,'unix');
    //                             return val;
    //                         })
    //                     }else{
    //                         this.$message.error(ret.msg);
    //                     }
    //                 })
    //       }
    //     },
    // created(){
    //          this.fan()   
    // },
    data () {
        return {
            // list:[],
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1518 弄',
                zip: 200333
            }, {
                date: '2016-05-04',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1517 弄',
                zip: 200333
            }, {
                date: '2016-05-01',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1519 弄',
                zip: 200333
            }, {
                date: '2016-05-03',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1516 弄',
                zip: 200333
            }]
        }
    }
}
</script>